import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import ProjectPage from 'components/ProjectPage'

const Project = ({ data }) => (
  <>
    <Helmet title='Vynl | François Deguire' />
    <ProjectPage
      name='Vynl'
      description={`
      Vynl is an intelligent turntable that can be controlled by using an application on your phone. 
      Realized in a 3D animation course, a trailer was created for this fictional product to 
      demonstrate knowledge of rendering and animation software as well as the ability to showcase a 
      product and its functionality through digital mediums.
      `}
      meta={`
        For Université de Montréal<br>
        In 2017<br><br>
        <a href="https://bit.ly/2QXQtIa">Full Video</a>
      `}
      images={Object.values(data).map(v => v.childImageSharp.fluid)}
    />
  </>
)

export const query = graphql`
  query {
    vynl1: file(relativePath: { eq: "vynl/1.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vynl2: file(relativePath: { eq: "vynl/2.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vynl3: file(relativePath: { eq: "vynl/3.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vynl4: file(relativePath: { eq: "vynl/4.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vynl5: file(relativePath: { eq: "vynl/5.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    vynl6: file(relativePath: { eq: "vynl/6.webp" }) {
      childImageSharp {
        fluid(webpQuality: 100, maxWidth: 2200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default Project
